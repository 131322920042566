import { forwardRef } from "react";
import { toCurrency } from "../utils/currency-format";

export const QuotePdf = forwardRef(
    (props: { data: any; pdfStyles: any }, ref) => {
        return (
            <section
                // @ts-ignore
                ref={ref}
                style={{
                    width: "800px",
                    padding: "20px 50px",
                    fontSize: "14px",
                    ...props.pdfStyles,
                }}
            >
                <div style={{ display: "flex" }}>
                    <span style={{ marginBottom: "20px" }}>
                        {new Date().toLocaleDateString()},
                        {`${new Date().toLocaleTimeString("en-US", {
                            hour: "2-digit",
                            minute: "2-digit",
                        })}`}
                    </span>
                    <span style={{ marginLeft: "auto", marginRight: "30%" }}>
                        Ally
                    </span>
                </div>

                <img
                    src="/logo-ally-auto.png"
                    alt=""
                    height="auto"
                    width="auto"
                />
                <h1
                    style={{
                        marginTop: "30px",
                        color: "#650360",
                        fontSize: "24px",
                        fontWeight: "bold",
                    }}
                >
                    ComTRAC Quick Quote Tool
                </h1>

                <p style={{ marginTop: "20px", fontSize: "14px" }}>
                    {new Date().toLocaleDateString()}
                </p>

                <p style={{ marginTop: "20px" }}>
                    Ally Financial appreciates the opportunity to provide the
                    following ComTRAC Quick Quote, which is based on estimated
                    values.
                </p>

                <p style={{ marginTop: "20px" }}>
                    This calculation does not consider all of the factors used
                    to determine a monthly payment amount. To generate a full
                    quote, or to complete ComTRAC documentation, use the ComTRAC
                    Quote and Document Tool under <b>Retail Sales & Services</b>{" "}
                    in the Ally Dealer Portal.
                </p>

                {props.data.quoteLabel && (
                    <p style={{ marginTop: "30px", fontWeight: "bold" }}>
                        {props.data.quoteLabel}
                    </p>
                )}

                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "30px",
                    }}
                >
                    <table cellPadding="1">
                        <tbody>
                            <tr>
                                <td style={{ textAlign: "right" }}>
                                    <b>Selling Price</b>
                                </td>
                                <td style={{ paddingLeft: "10px" }}>
                                    {toCurrency(
                                        parseFloat(props.data.sellingPrice) || 0
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "right" }}>
                                    <b>Upfit/Dealer Installed Options</b>
                                </td>
                                <td style={{ paddingLeft: "10px" }}>
                                    {toCurrency(
                                        parseFloat(
                                            props.data.dealerInstalledOptions
                                        ) || 0
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "right" }}>
                                    <b>Capitalized Taxes and Other Fees</b>
                                </td>
                                <td style={{ paddingLeft: "10px" }}>
                                    {toCurrency(
                                        parseFloat(
                                            props.data.capitalizedTaxes
                                        ) || 0
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "right" }}>
                                    <b>Administrative Fee</b>
                                </td>
                                <td style={{ paddingLeft: "10px" }}>
                                    {toCurrency(
                                        parseFloat(
                                            props.data.administrativeFee
                                        ) || 0
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "right" }}>
                                    <b>Rebates</b>
                                </td>
                                <td style={{ paddingLeft: "10px" }}>
                                    {toCurrency(
                                        parseFloat(props.data.rebates) || 0
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "right" }}>
                                    <b>Cap Cost Reduction</b>
                                </td>
                                <td style={{ paddingLeft: "10px" }}>
                                    {toCurrency(
                                        parseFloat(
                                            props.data.capCostReduction
                                        ) || 0
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "right" }}>
                                    <b>Trade-in Equity</b>
                                </td>
                                <td style={{ paddingLeft: "10px" }}>
                                    {toCurrency(
                                        parseFloat(props.data.tradeInEquity) ||
                                            0
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "right" }}>
                                    <b>Term</b>
                                </td>
                                <td style={{ paddingLeft: "10px" }}>
                                    {props.data.term}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "right" }}>
                                    <b>Residual</b>
                                </td>
                                <td style={{ paddingLeft: "10px" }}>
                                    {props.data.residual}%
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "right" }}>
                                    <b>Sales/Use Tax</b>
                                </td>
                                <td style={{ paddingLeft: "10px" }}>
                                    {props.data.salesTax}%
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "right" }}>
                                    <b>Total Cap Cost Reduction</b>
                                </td>
                                <td style={{ paddingLeft: "10px" }}>
                                    {toCurrency(
                                        parseFloat(
                                            props.data.totalCapCostReduction
                                        ) || 0
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "right" }}>
                                    <b>Net Cap Cost</b>
                                </td>
                                <td style={{ paddingLeft: "10px" }}>
                                    {toCurrency(
                                        parseFloat(props.data.netCapCost) || 0
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "right" }}>
                                    <b>Residual Amount</b>
                                </td>
                                <td style={{ paddingLeft: "10px" }}>
                                    {toCurrency(
                                        parseFloat(props.data.residualAmount) ||
                                            0
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div
                        style={{
                            backgroundColor: "#E1E1E1",
                            borderRadius: "10px",
                            marginLeft: "30px",
                            height: "min-content",
                            padding: "40px 10px",
                            width: "350px",
                        }}
                    >
                        <table style={{ marginLeft: "30px" }}>
                            <tbody>
                                <tr>
                                    <td
                                        style={{
                                            textAlign: "right",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Base Monthly Payment
                                    </td>
                                    <td style={{ paddingLeft: "10px" }}>
                                        {toCurrency(
                                            parseFloat(
                                                props.data.baseMonthlyPayment
                                            )
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            textAlign: "right",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Monthly Sales Tax
                                    </td>
                                    <td style={{ paddingLeft: "10px" }}>
                                        {toCurrency(
                                            parseFloat(
                                                props.data.monthlySalesTax
                                            )
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            textAlign: "right",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Estimated Monthly Payment
                                    </td>
                                    <td style={{ paddingLeft: "10px" }}>
                                        {toCurrency(
                                            parseFloat(
                                                props.data
                                                    .estimatedMonthlyPayment
                                            )
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <h3 style={{ fontSize: "18px", marginTop: "20px" }}>
                    Please note the following information:
                </h3>
                <p style={{ fontSize: "13px", marginTop: "5px" }}>
                    This is not a credit approval or an offer of credit. All
                    transactions are subject to credit review and approval by
                    the Ally Commercial Services Group. All transactions must
                    meet Ally Financial program requirements. If a transaction
                    results, the dealership is responsible for ensuring the
                    final contract terms, including rate and taxes, are accurate
                    and in compliance with program requirements. Contract terms
                    are subject to validation by the Ally Commercial Services
                    Group.
                </p>
            </section>
        );
    }
);
