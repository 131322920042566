import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useRef, useState } from "react";
import QuoteDetails from "./quote-details";
// @ts-ignore
import ReactToPdf from "react-to-pdf";
import { QuotePdf } from "./quote-pdf";
import ReactToPrint from "react-to-print";
import { QuoteModal } from "./quote-modal";

const QuoteSchema = Yup.object().shape({
    sellingPrice: Yup.string()
        .required("Please enter the Selling Price.")
        .matches(
            /^[0-9]{0,7}(\.[0-9]{1,2})?$/,
            "Please enter the selling price amount using the format: 0000000.00"
        ),
    administrativeFeeStates: Yup.string().required("Please enter Admin Fee."),
    administrativeFee: Yup.string()
        .required("Please enter Admin Fee.")
        .matches(
            /^[0-9]{0,7}(\.[0-9]{1,2})?$/,
            "Please enter the administrative fee amount using the format: 0000000.00"
        ),
    term: Yup.string().required("Please enter a Term."),
    capCostReduction: Yup.string()
        .matches(
            /^[0-9]{0,7}(\.[0-9]{1,2})?$/,
            "Please enter the cap cost reduction amount using the format: 0000000.00"
        )
        .test(
            "is-sum-lt-selling-price",
            "The combined total of the Rebates, Cap Cost Reduction and Trade-In Equity should be less than the Selling price.",
            (value, context) => {
                let { rebates, tradeInEquity, sellingPrice } = context.parent;

                if (value && sellingPrice) {
                    if (
                        (parseInt(rebates) || 0) +
                            (parseInt(tradeInEquity) || 0) +
                            parseInt(value) >=
                        parseInt(sellingPrice)
                    ) {
                        // Only show this error if rebates is not filled
                        if (rebates === undefined) {
                            return false;
                        }
                        return true;
                    }
                }
                return true;
            }
        ),
    dealerInstalledOptions: Yup.string().matches(
        /^[0-9]{0,7}(\.[0-9]{1,2})?$/,
        "Please enter the upfit/dealer installed options amount using the format: 0000000.00"
    ),
    capitalizedTaxes: Yup.string().matches(
        /^[0-9]{0,7}(\.[0-9]{1,2})?$/,
        "Please enter the capitalized taxes or other fees amount using the format: 0000000.00"
    ),
    tradeInEquity: Yup.string()
        .matches(
            /^[0-9]{0,7}(\.[0-9]{1,2})?$/,
            "Please enter the trade-in equity amount using the format: 0000000.00"
        )
        .test(
            "is-sum-lt-selling-price",
            "The combined total of the Rebates, Cap Cost Reduction and Trade-In Equity should be less than the Selling price.",
            (value, context) => {
                let { capCostReduction, rebates, sellingPrice } =
                    context.parent;

                if (value && sellingPrice) {
                    if (
                        (parseInt(capCostReduction) || 0) +
                            (parseInt(rebates) || 0) +
                            parseInt(value) >=
                        parseInt(sellingPrice)
                    ) {
                        // Only show this error if rebates and cap cost reduction aren't shown
                        if (
                            rebates === undefined &&
                            capCostReduction === undefined
                        ) {
                            return false;
                        }
                        return true;
                    }
                }
                return true;
            }
        ),
    rebates: Yup.number()
        .typeError(
            "Please enter the rebates amount using the format: 0000000.00"
        )
        .test(
            "is-decimal",
            "Please enter the rebates amount using the format: 0000000.00",
            // @ts-ignore
            (value) => {
                if (value) {
                    return (value + "").match(/^[0-9]{0,7}(\.[0-9]{1,2})?$/);
                }
                return true;
            }
        )
        .test(
            "is-sum-lt-selling-price",
            "The combined total of the Rebates, Cap Cost Reduction and Trade-In Equity should be less than the Selling price.",
            (value, context) => {
                let { capCostReduction, tradeInEquity, sellingPrice } =
                    context.parent;

                if (value !== undefined && value >= 0 && sellingPrice) {
                    if (
                        (parseInt(capCostReduction) || 0) +
                            (parseInt(tradeInEquity) || 0) +
                            value >=
                        parseInt(sellingPrice)
                    ) {
                        return false;
                    }
                }
                return true;
            }
        ),
    residual: Yup.number()
        .typeError("Please enter the residual % using the format: 00.00")
        .required("Please enter Residual %.")
        .when("term", (term): any => {
            if (term === "12") {
                return Yup.number()
                    .typeError(
                        "Please enter the residual % using the format: 00.00"
                    )
                    .max(50, "Residual % exceeds maximum allowed for term.")
                    .required("Please enter Residual %")
                    .min(
                        0,
                        "Please enter the residual % using the format: 00.00"
                    )
                    .test(
                        "is-decimal",
                        "Please enter the residual tax using the format: 00.00",
                        // @ts-ignore
                        (value) => {
                            if (value) {
                                return (value + "").match(
                                    /^[0-9]{0,2}(\.[0-9]{1,2})?$/
                                );
                            }
                            return true;
                        }
                    );
            } else if (term === "24") {
                return Yup.number()
                    .typeError(
                        "Please enter the residual % using the format: 00.00"
                    )
                    .max(45, "Residual % exceeds maximum allowed for term.")
                    .required("Please enter Residual %")
                    .min(
                        0,
                        "Please enter the residual % using the format: 00.00"
                    )
                    .test(
                        "is-decimal",
                        "Please enter the residual tax using the format: 00.00",
                        // @ts-ignore
                        (value) => {
                            if (value) {
                                return (value + "").match(
                                    /^[0-9]{0,2}(\.[0-9]{1,2})?$/
                                );
                            }
                            return true;
                        }
                    );
            } else if (term === "36") {
                return Yup.number()
                    .typeError(
                        "Please enter the residual % using the format: 00.00"
                    )

                    .max(40, "Residual % exceeds maximum allowed for term.")
                    .required("Please enter Residual %")
                    .min(
                        0,
                        "Please enter the residual % using the format: 00.00"
                    )
                    .test(
                        "is-decimal",
                        "Please enter the residual tax using the format: 00.00",
                        // @ts-ignore
                        (value) => {
                            if (value) {
                                return (value + "").match(
                                    /^[0-9]{0,2}(\.[0-9]{1,2})?$/
                                );
                            }
                            return true;
                        }
                    );
            } else if (term === "48") {
                return Yup.number()
                    .typeError(
                        "Please enter the residual % using the format: 00.00"
                    )

                    .max(30, "Residual % exceeds maximum allowed for term.")
                    .required("Please enter Residual %")
                    .min(
                        0,
                        "Please enter the residual % using the format: 00.00"
                    )
                    .test(
                        "is-decimal",
                        "Please enter the residual tax using the format: 00.00",
                        // @ts-ignore
                        (value) => {
                            if (value) {
                                return (value + "").match(
                                    /^[0-9]{0,2}(\.[0-9]{1,2})?$/
                                );
                            }
                            return true;
                        }
                    );
            } else if (term === "60") {
                return Yup.number()
                    .typeError(
                        "Please enter the residual % using the format: 00.00"
                    )

                    .max(25, "Residual % exceeds maximum allowed for term.")
                    .required("Please enter Residual %")
                    .min(
                        0,
                        "Please enter the residual % using the format: 00.00"
                    )
                    .test(
                        "is-decimal",
                        "Please enter the residual tax using the format: 00.00",
                        // @ts-ignore
                        (value) => {
                            if (value) {
                                return (value + "").match(
                                    /^[0-9]{0,2}(\.[0-9]{1,2})?$/
                                );
                            }
                            return true;
                        }
                    );
            } else if (term === "72") {
                return Yup.number()
                    .typeError(
                        "Please enter the residual % using the format: 00.00"
                    )

                    .max(15, "Residual % exceeds maximum allowed for term.")
                    .required("Please enter Residual %")
                    .min(
                        0,
                        "Please enter the residual % using the format: 00.00"
                    )
                    .test(
                        "is-decimal",
                        "Please enter the residual tax using the format: 00.00",
                        // @ts-ignore
                        (value) => {
                            if (value) {
                                return (value + "").match(
                                    /^[0-9]{0,2}(\.[0-9]{1,2})?$/
                                );
                            }
                            return true;
                        }
                    );
            }
        }),
    salesTax: Yup.number()
        .typeError("Please enter the sales/use tax using the format: 00.00")
        .min(0, "Please enter the sales/use tax using the format: 00.00")
        .max(100, "Please enter the sales/use tax using the format: 00.00")
        .test(
            "is-decimal",
            "Please enter the sales/use tax using the format: 00.00",
            // @ts-ignore
            (value) => {
                if (value) {
                    return (value + "").match(/^[0-9]{0,2}(\.[0-9]{1,2})?$/);
                }
                return true;
            }
        ),
    customerRate: Yup.number()
        .typeError("Please enter the customer rate using the format: 00.00")
        .required("Please enter Customer Rate %.")
        .min(0, " Please enter the customer rate using the format: 00.00")
        .max(99, " Please enter the customer rate using the format: 00.00")
        .test(
            "is-decimal",
            "Please enter the customer rate using the format: 00.00",
            (value) =>
                // @ts-ignore
                (value + "").match(/^[0-9]{0,2}(\.[0-9]{1,2})?$/)
        ),
});

export default function QuoteForm() {
    const [quoteDetails, setQuoteDetails] = useState<any>();
    const [showModal, setShowModal] = useState<any>(false);

    const [pdfStyles, setPDFStyles] = useState<any>({
        position: "absolute",
        left: "-10000px",
    });

    const pdfRef = useRef(null);

    const quoteForm = useFormik({
        initialValues: {
            sellingPrice: "",
            term: "",
            dealerInstalledOptions: "",
            residual: "",
            capitalizedTaxes: "",
            salesTax: "",
            administrativeFeeStates: "",
            administrativeFee: "250",
            customerRate: "",
            rebates: "",
            quoteLabel: "",
            capCostReduction: "",
            tradeInEquity: "",
        },
        onSubmit: async (values) => {
            let transformedValues = {
                payload: {
                    sellingPrice: values.sellingPrice
                        ? parseFloat(values.sellingPrice)
                        : 0,
                    term: values.term ? parseFloat(values.term) : 0,
                    dealerInstalledOptions: values.dealerInstalledOptions
                        ? parseFloat(values.dealerInstalledOptions)
                        : 0,
                    residual: values.residual ? parseFloat(values.residual) : 0,
                    capitalizedTaxes: values.capitalizedTaxes
                        ? parseFloat(values.capitalizedTaxes)
                        : 0,
                    salesTax: values.salesTax ? parseFloat(values.salesTax) : 0,
                    administrativeFeeStates: values.administrativeFeeStates
                        ? parseFloat(values.administrativeFeeStates)
                        : 0,
                    administrativeFee: values.administrativeFee
                        ? parseFloat(values.administrativeFee)
                        : 0,
                    customerRate: values.customerRate
                        ? parseFloat(values.customerRate)
                        : 0,
                    rebates: values.rebates ? parseFloat(values.rebates) : 0,
                    capCostReduction: values.capCostReduction
                        ? parseFloat(values.capCostReduction)
                        : 0,
                    tradeInEquity: values.tradeInEquity
                        ? parseFloat(values.tradeInEquity)
                        : 0,
                    quoteLabel: values.quoteLabel,
                },
            };

            if (process.env.REACT_APP_QUOTE_API) {
                const result = await axios.post(
                    process.env.REACT_APP_QUOTE_API,
                    transformedValues,
                    {
                        headers: {
                            Authorization: "allow",
                        },
                    }
                );
                setQuoteDetails(result.data);
            }
        },
        validationSchema: QuoteSchema,
    });

    const onResetHandler = () => {
        setShowModal(true);
    };

    const clearResetHandler = () => {
        setShowModal(false);
        quoteForm.resetForm();
    };

    return (
        <div>
            <QuoteModal
                show={showModal}
                updateModal={setShowModal}
                clearResetHandler={clearResetHandler}
            />
            <form className="px-2 md:px-0" onSubmit={quoteForm.handleSubmit}>
                <div className="grid grid-cols-1 md:grid-cols-2 mt-5">
                    <div className="first-column">
                        <div className="grid gap-1 grid-cols-2 grid-flow-row justify-end">
                            {quoteForm.errors.sellingPrice &&
                                quoteForm.touched.sellingPrice && (
                                    <p className="col-start-2 flex items-center leading-none text-[13px] text-[#C00] mt-auto">
                                        <img
                                            src="error.gif"
                                            alt=""
                                            className="mr-2"
                                        />
                                        {quoteForm.errors.sellingPrice}
                                    </p>
                                )}
                            <label
                                htmlFor="sellingPrice"
                                className="text-right my-auto mr-2"
                            >
                                * Selling Price
                            </label>
                            <div className="input-container w-100">
                                <p className="input-before">$</p>
                                <input
                                    type="text"
                                    id="sellingPrice"
                                    name="sellingPrice"
                                    value={quoteForm.values.sellingPrice}
                                    onChange={quoteForm.handleChange}
                                    className="border-solid border border-black p-2 pl-6 w-full"
                                    tabIndex={1}
                                />
                            </div>
                        </div>

                        <div className="grid gap-1 grid-cols-2 grid-flow-row justify-end mt-3">
                            {quoteForm.touched.dealerInstalledOptions &&
                                quoteForm.errors.dealerInstalledOptions && (
                                    <p className="col-start-2 flex items-start leading-none text-[13px] text-[#C00] mt-auto">
                                        <img
                                            src="error.gif"
                                            alt=""
                                            className="mr-2"
                                        />
                                        {
                                            quoteForm.errors
                                                .dealerInstalledOptions
                                        }
                                    </p>
                                )}
                            <label
                                htmlFor="dealerInstalledOptions"
                                className="text-right my-auto mr-2"
                            >
                                Upfit/Dealer Installed Options
                            </label>
                            <div className="input-container w-100">
                                <p className="input-before">$</p>
                                <input
                                    type="text"
                                    id="dealerInstalledOptions"
                                    name="dealerInstalledOptions"
                                    value={
                                        quoteForm.values.dealerInstalledOptions
                                    }
                                    onChange={quoteForm.handleChange}
                                    className="border-solid border border-black p-2 pl-6 w-full"
                                    tabIndex={3}
                                />
                            </div>
                        </div>

                        <div className="grid gap-1 grid-cols-2 grid-flow-row justify-end mt-3">
                            {quoteForm.touched.capitalizedTaxes &&
                                quoteForm.errors.capitalizedTaxes && (
                                    <p className="col-start-2 flex items-start leading-none text-[13px] text-[#C00] mt-auto">
                                        <img
                                            src="error.gif"
                                            alt=""
                                            className="mr-2"
                                        />
                                        {quoteForm.errors.capitalizedTaxes}
                                    </p>
                                )}
                            <label
                                htmlFor="capitalizedTaxes"
                                className="text-right my-auto mr-2"
                            >
                                Capitalized Taxes or Other Fees
                            </label>
                            <div className="input-container w-100">
                                <p className="input-before">$</p>
                                <input
                                    type="text"
                                    id="capitalizedTaxes"
                                    name="capitalizedTaxes"
                                    value={quoteForm.values.capitalizedTaxes}
                                    onChange={quoteForm.handleChange}
                                    className="border-solid border border-black p-2 pl-6 w-full"
                                    tabIndex={5}
                                />
                            </div>
                        </div>

                        <div className="mt-3">
                            <div className="grid gap-1 grid-cols-2 grid-flow-row justify-end mt-3">
                                {quoteForm.touched.administrativeFeeStates &&
                                    quoteForm.errors
                                        .administrativeFeeStates && (
                                        <p className="col-start-2 flex items-start leading-none text-[13px] text-[#C00] mt-auto">
                                            <img
                                                src="error.gif"
                                                alt=""
                                                className="mr-2"
                                            />
                                            {
                                                quoteForm.errors
                                                    .administrativeFeeStates
                                            }
                                        </p>
                                    )}
                                {/* {quoteForm.touched.administrativeFee &&
                                    quoteForm.errors.administrativeFee && (
                                        <p className="col-start-2 flex items-start leading-none text-[13px] self-start text-start text-[#C00] mt-auto">
                                            <img
                                                src="error.gif"
                                                alt=""
                                                className="mr-2"
                                            />
                                            {quoteForm.errors.administrativeFee}
                                        </p>
                                    )} */}
                                <label
                                    htmlFor="Term"
                                    className="text-right justify-end items-center my-auto mr-2"
                                >
                                    * Administrative Fee
                                </label>
                                <select
                                    name="administrativeFeeStates"
                                    id="administrativeFeeStates"
                                    value={
                                        quoteForm.values.administrativeFeeStates
                                    }
                                    onChange={quoteForm.handleChange}
                                    className="border-solid border border-black p-2 pl-1"
                                    tabIndex={7}
                                >
                                    <option value="" disabled={true}>
                                        Select State
                                    </option>
                                    <option value="all">All states</option>
                                </select>
                            </div>

                            {quoteForm.values.administrativeFeeStates && (
                                <div className="grid gap-1 grid-cols-2 grid-flow-row justify-end mt-3">
                                    {quoteForm.touched.administrativeFee &&
                                        quoteForm.errors.administrativeFee && (
                                            <p className="col-start-2 flex items-start leading-none text-[13px] self-start text-start text-[#C00] mt-auto">
                                                <img
                                                    src="error.gif"
                                                    alt=""
                                                    className="mr-2"
                                                />
                                                {
                                                    quoteForm.errors
                                                        .administrativeFee
                                                }
                                            </p>
                                        )}
                                    <div className="input-container w-100 col-start-2">
                                        <p className="input-before">$</p>
                                        <input
                                            type="text"
                                            id="administrativeFee"
                                            name="administrativeFee"
                                            value={
                                                quoteForm.values
                                                    .administrativeFee
                                            }
                                            onChange={(e) => {
                                                e.preventDefault();

                                                const { value } = e.target;

                                                if (value.match(/^[.0-9]*$/)) {
                                                    console.log("Matched");
                                                    quoteForm.handleChange(e);
                                                }
                                            }}
                                            className="border-solid border border-black p-2 pl-6 w-full"
                                            tabIndex={9}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="grid gap-1 grid-cols-2 grid-flow-row justify-end mt-3">
                            {quoteForm.touched.rebates &&
                                quoteForm.errors.rebates && (
                                    <p className="col-start-2 flex items-start leading-none text-[13px] text-[#C00] mt-auto">
                                        <img
                                            src="error.gif"
                                            alt=""
                                            className="mr-2"
                                        />
                                        {quoteForm.errors.rebates}
                                    </p>
                                )}
                            {/* {quoteForm.touched.rebates &&
                                isSellingPriceExceeded && (
                                    <p className="col-start-2 flex items-start text-[#C00] mt-auto">
                                        <img
                                            src="error.gif"
                                            alt=""
                                            className="mr-2"
                                        />
                                        {rebateErrorMessage}
                                    </p>
                                )} */}
                            <label
                                htmlFor="rebates"
                                className="text-right my-auto mr-2"
                            >
                                Rebates
                            </label>
                            <div className="input-container w-100 col-start-2">
                                <p className="input-before">$</p>
                                <input
                                    type="text"
                                    id="rebates"
                                    name="rebates"
                                    value={quoteForm.values.rebates}
                                    onChange={quoteForm.handleChange}
                                    className="border-solid border border-black p-2 pl-6 w-full"
                                    tabIndex={11}
                                />
                            </div>
                        </div>

                        <div className="grid gap-1 grid-cols-2 grid-flow-row justify-end mt-3">
                            {quoteForm.touched.capCostReduction &&
                                quoteForm.errors.capCostReduction && (
                                    <p className="col-start-2 flex items-start leading-none text-[13px] text-[#C00] mt-auto">
                                        <img
                                            src="error.gif"
                                            alt=""
                                            className="mr-2"
                                        />
                                        {quoteForm.errors.capCostReduction}
                                    </p>
                                )}
                            <label
                                htmlFor="capCostReduction"
                                className="text-right my-auto mr-2"
                            >
                                Cap Cost Reduction
                            </label>
                            <div className="input-container w-100 col-start-2">
                                <p className="input-before">$</p>
                                <input
                                    type="text"
                                    id="capCostReduction"
                                    name="capCostReduction"
                                    value={quoteForm.values.capCostReduction}
                                    onChange={quoteForm.handleChange}
                                    className="border-solid border border-black p-2 pl-6 w-full"
                                    tabIndex={13}
                                />
                            </div>
                        </div>

                        <div className="grid gap-1 grid-cols-2 grid-flow-row justify-end mt-3">
                            {quoteForm.touched.tradeInEquity &&
                                quoteForm.errors.tradeInEquity && (
                                    <p className="col-start-2 flex items-start leading-none text-[13px] text-[#C00] mt-auto">
                                        <img
                                            src="error.gif"
                                            alt=""
                                            className="mr-2"
                                        />
                                        {quoteForm.errors.tradeInEquity}
                                    </p>
                                )}
                            <label
                                htmlFor="tradeInEquity"
                                className="text-right my-auto mr-2"
                            >
                                Trade-In Equity
                            </label>
                            <div className="input-container w-100 col-start-2">
                                <p className="input-before">$</p>
                                <input
                                    type="text"
                                    id="tradeInEquity"
                                    name="tradeInEquity"
                                    value={quoteForm.values.tradeInEquity}
                                    onChange={quoteForm.handleChange}
                                    className="border-solid border border-black p-2 pl-6 w-full"
                                    tabIndex={15}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="second-column">
                        <div className="grid gap-1 grid-cols-2 grid-flow-row justify-end">
                            {quoteForm.errors.term &&
                                quoteForm.touched.term && (
                                    <p className="col-start-2 flex items-start leading-none text-[13px] text-[#C00] mt-auto">
                                        <img
                                            src="error.gif"
                                            alt=""
                                            className="mr-2"
                                        />
                                        {quoteForm.errors.term}
                                    </p>
                                )}

                            <label
                                htmlFor="Term"
                                className="text-right my-auto mr-2"
                            >
                                * Term
                            </label>
                            <select
                                name="term"
                                id="term"
                                value={quoteForm.values.term}
                                onChange={quoteForm.handleChange}
                                className="w-full border-solid border border-black py-2 p-1"
                                tabIndex={2}
                            >
                                <option value="" disabled={true}>
                                    Select Term
                                </option>
                                <option value="12">12</option>
                                <option value="24">24</option>
                                <option value="36">36</option>
                                <option value="48">48</option>
                                <option value="60">60</option>
                                <option value="72">72</option>
                            </select>
                        </div>

                        <div className="grid gap-1 grid-cols-2 grid-flow-row justify-end mt-3">
                            {quoteForm.touched.residual &&
                                quoteForm.errors.residual && (
                                    <p className="col-start-2 flex items-start leading-none text-[13px] text-[#C00] mt-auto">
                                        <img
                                            src="error.gif"
                                            alt=""
                                            className="mr-2"
                                        />

                                        {quoteForm.errors.residual}
                                    </p>
                                )}
                            <label
                                htmlFor="residual"
                                className="text-right my-auto mr-2"
                            >
                                * Residual
                            </label>
                            <div className="input-container w-100 col-start-2">
                                <input
                                    type="text"
                                    id="residual"
                                    name="residual"
                                    value={quoteForm.values.residual}
                                    onChange={quoteForm.handleChange}
                                    className="border-solid border border-black p-2 w-full"
                                    tabIndex={4}
                                />
                                <p className="input-after">%</p>
                            </div>
                        </div>

                        <div className="grid gap-1 grid-cols-2 grid-flow-row justify-end mt-3">
                            {quoteForm.touched.salesTax &&
                                quoteForm.errors.salesTax && (
                                    <p className="col-start-2 flex items-start leading-none text-[13px] text-[#C00] mt-auto">
                                        <img
                                            src="error.gif"
                                            alt=""
                                            className="mr-2"
                                        />
                                        {quoteForm.errors.salesTax}
                                    </p>
                                )}
                            <label
                                htmlFor="salesTax"
                                className="text-right my-auto mr-2"
                            >
                                Sales/Use Tax
                            </label>
                            <div className="input-container w-100 col-start-2">
                                <input
                                    type="text"
                                    id="salesTax"
                                    name="salesTax"
                                    value={quoteForm.values.salesTax}
                                    onChange={quoteForm.handleChange}
                                    className="border-solid border border-black p-2 w-full"
                                    tabIndex={6}
                                />
                                <p className="input-after">%</p>
                            </div>
                        </div>

                        <div className="grid gap-1 grid-cols-2 grid-flow-row justify-end mt-3">
                            {quoteForm.touched.customerRate &&
                                quoteForm.errors.customerRate && (
                                    <p className="col-start-2 flex items-start leading-none text-[13px] text-[#C00] mt-auto">
                                        <img
                                            src="error.gif"
                                            alt=""
                                            className="mr-2"
                                        />
                                        {quoteForm.errors.customerRate}
                                    </p>
                                )}
                            <label
                                htmlFor="customerRate"
                                className="text-right my-auto mr-2"
                            >
                                * Customer Rate
                            </label>
                            <div className="input-container w-100 col-start-2">
                                <input
                                    type="text"
                                    id="customerRate"
                                    name="customerRate"
                                    value={quoteForm.values.customerRate}
                                    onChange={quoteForm.handleChange}
                                    className="border-solid border border-black p-2 w-full"
                                    tabIndex={8}
                                />
                                <p className="input-after">%</p>
                            </div>
                        </div>

                        <div className="grid gap-1 grid-cols-2 grid-flow-row justify-end mt-3">
                            <label
                                htmlFor="quoteLabel"
                                className="justify-end text-end h-[150px] mr-2"
                            >
                                Label This Quote
                            </label>
                            <textarea
                                name="quoteLabel"
                                id="quoteLabel"
                                value={quoteForm.values.quoteLabel}
                                onChange={quoteForm.handleChange}
                                maxLength={250}
                                className="w-full border-solid border border-black p-1 text-[14px]"
                                placeholder="Enter any additional information you'd like to include in the printed quote (e.g., customer name, phone number, etc). Do not include sensitive information such as Social Security numbers or Tax ID numbers."
                                tabIndex={10}
                            />
                        </div>
                    </div>
                </div>

                {quoteDetails && <QuoteDetails {...quoteDetails} />}

                <div className="form-actions flex mt-3 justify-start md:justify-end ">
                    {quoteDetails ? (
                        <div className="w-3/4 flex">
                            <button className="border border-solid border-black px-4 py-1 bg-[#0071C4] text-white">
                                Re-calculate Payment
                            </button>
                            <div className="other-options ml-5 my-auto">
                                <div>
                                    <ReactToPrint
                                        trigger={() => (
                                            <button
                                                className="font-bold"
                                                type="button"
                                            >
                                                Print
                                            </button>
                                        )}
                                        content={() => pdfRef.current}
                                        onBeforeGetContent={async () =>
                                            await setPDFStyles({
                                                position: "absolute",
                                                zIndex: -10,
                                            })
                                        }
                                        onAfterPrint={() =>
                                            setPDFStyles({
                                                position: "absolute",
                                                left: "-10000px",
                                            })
                                        }
                                    ></ReactToPrint>

                                    <span className="ml-1">|</span>
                                    <ReactToPdf
                                        targetRef={pdfRef}
                                        filename="ComTRAC_Quick_Quote.pdf"
                                    >
                                        {({ toPdf }: any) => (
                                            <button
                                                className="ml-1 font-bold"
                                                onClick={toPdf}
                                            >
                                                Save
                                            </button>
                                        )}
                                    </ReactToPdf>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="w-3/4 flex">
                            <button className="border border-solid border-black px-4 py-2 bg-[#006898] text-white">
                                Calculate Payment
                            </button>
                            <button
                                className="border border-solid border-black px-4 py-2 bg-gray ml-3 bg-[#85b1c4] text-white"
                                onClick={onResetHandler}
                                type="button"
                            >
                                Reset
                            </button>
                        </div>
                    )}
                </div>
            </form>

            <QuotePdf
                ref={pdfRef}
                data={{ ...quoteForm.values, ...quoteDetails, ...pdfStyles }}
                pdfStyles={pdfStyles}
            />
        </div>
    );
}
