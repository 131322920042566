import { toCurrency } from "../utils/currency-format";
function QuoteDetail({ name, value }: any) {
    return (
        <div className="flex justify-center pt-1">
            <b className="w-2/3">{name}:</b>
            <span className="w-1/3 text-left pl-2">{toCurrency(value)}</span>
        </div>
    );
}

export default function QuoteDetails(props: any) {
    return (
        <div className="md:flex md:text-right mt-3">
            <span className="md:w-1/4 justify-end items-center mr-1">
                Quick Quote Details:
            </span>
            <div className="md:w-1/2">
                <div className="intermediate-calculations bg-[#BDD0D7] pt-7 pb-4 px-1 md:px-0">
                    <QuoteDetail
                        name="Total Cap Cost Reduction"
                        value={props.totalCapCostReduction}
                    />
                    <QuoteDetail name="Net Cap Cost" value={props.netCapCost} />
                    <QuoteDetail
                        name="Residual Amount"
                        value={props.residualAmount}
                    />
                </div>
                <div className="quote-details mt-1 bg-[#BDD0D7] pt-4 pb-7 px-2 md:px-0">
                    <QuoteDetail
                        name="Base Monthly Payment"
                        value={props.baseMonthlyPayment}
                    />
                    <QuoteDetail
                        name="Monthly Sales Tax"
                        value={props.monthlySalesTax}
                    />
                    <QuoteDetail
                        name="Estimated Monthly Payment"
                        value={props.estimatedMonthlyPayment}
                    />
                </div>
            </div>
        </div>
    );
}
