import { Dialog } from "@headlessui/react";

export function QuoteModal(props: any) {
    return (
        <Dialog
            open={props.show}
            onClose={() => props.updateModal(false)}
            as="div"
            className="relative z-10"
        >
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

            <div className="fixed inset-0 flex items-center justify-center p-4">
                <Dialog.Panel className="mx-auto max-w-md rounded bg-white py-4 px-12">
                    <Dialog.Title>
                        <button
                            type="button"
                            className="flex ml-auto items-center text-[#006899]"
                            onClick={() => props.updateModal(false)}
                        >
                            close
                            <img
                                src="/close-icon.gif"
                                alt=""
                                className="ml-2"
                            />
                        </button>
                    </Dialog.Title>
                    <Dialog.Description>
                        <p className="text-[14px]">
                            You are about to clear all fields. Permanently
                            remove all data?
                        </p>
                    </Dialog.Description>

                    <div className="flex justify-end">
                        <button
                            type="button"
                            onClick={props.clearResetHandler}
                            className="px-2 bg-[#650360] text-white border rounded-md"
                        >
                            yes, clear all fields
                        </button>
                        <button
                            type="button"
                            onClick={() => props.updateModal(false)}
                            className="text-[#006899] border-2 rounded-md px-2 ml-2"
                        >
                            cancel
                        </button>
                    </div>
                </Dialog.Panel>
            </div>
        </Dialog>
    );
}
