import React from "react";
import "./App.css";
import QuoteForm from "./components/quote-form";

function App() {
    return (
        <main className="container pb-8" style={{ backgroundColor: "#F2F2F2" }}>
            <h1 className="text-4xl text-[#650360]">
                Tools | <b>ComTRAC Quick Quote</b>
            </h1>
            <p className="mt-5">
                This calculator produces ComTRAC lease payment estimates.
            </p>

            <p className="mt-5">
                Use ComTRAC Quick Quote to generate a lease payment estimate. To
                generate a full quote, or to complete ComTRAC documentation, use
                the ComTRAC Quote and Document Tool under
                <b className="ml-1">Retail Sales & Services</b> in the Ally
                Dealer Portal.
            </p>

            <p className="mt-5 text-[#650360]">* indicates a required field</p>

            <QuoteForm></QuoteForm>

            <aside className="mt-8">
                All transactions are subject to credit review and approval by
                the Ally Commercial Services Group. All transactions must meet
                Ally program requirements. If a transaction results, the
                dealership is responsible for ensuring that the final contract,
                including rate and taxes, are accurate and in compliance with
                program requirements. Contract terms subject to validation by
                the Commercial Services group.
            </aside>
        </main>
    );
}

export default App;
